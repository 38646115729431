.navbar-component {
    &-container {
        width: 100%;
        height: 70px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: white;
        padding: 0 25px;

        @media only screen and (max-width: 500px) {
            & img {
                height: 30px;
                width: 30px;
            }
        }
    }

    &-title {
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
        font-family: 'Poppins';
        margin-left: 10px;
        font-weight: bold;
        font-size: 28px;
        color: #46b9eb;

        @media only screen and (max-width: 500px) {
            font-size: 22px;
        }
    }
}
