.search-page {
    &-container {
        @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
        font-family: "Poppins", sans-serif;
        box-sizing: border-box;
        padding: 25px;
        overflow: hidden;
    }

    &-input {
        width: 100%;
        font-size: 16px;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid #ddd;
        outline-color: #ccc;
        margin-bottom: 20px;
    }

    &-title {
        margin-top: 0;
        font-size: 18px;
    }
}

.category {
    &-container {
        width: 150px;
        height: 110px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
            opacity: 0.8;
        }
    }

    &-img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: 0;
    }

    &-title {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 5px;
        left: 10px;
        color: white;
        z-index: 10;
    }
}

.categories-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.darken {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.home-page-container {
    @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
    font-family: "Poppins", sans-serif;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    min-height: calc(100vh - 95px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    &-wrapper {
        width: 100%;
        height: 100%;
    }
}

.stores-container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
}

.store-display {
    &-container {
        height: 100px;
        width: 400px;
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
        display: flex;
        cursor: pointer;
        transition: all 0.3s;
        margin-bottom: 25px;

        &:hover {
            border-color: #56ccf2;
        }

        &:active {
            transform: translateY(-1px);
        }

        &-closed {
            cursor: default;
            opacity: 0.5;
        }

        @media only screen and (max-width: 870px) {
            width: 100%;
        }
    }

    &-img {
        height: 100%;
        width: 90px;
        object-fit: cover;
        border-radius: 5px;
    }

    &-title {
        font-size: 16px;
        font-weight: bold;
        color: #252525;
        text-transform: capitalize;
        overflow: hidden; 
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 285px;
        
        @media only screen and (max-width: 870px) {
            width: 600px;
        }

        @media only screen and (max-width: 760px) {
            width: 500px;
        }

        @media only screen and (max-width: 670px) {
            width: 400px;
        }

        @media only screen and (max-width: 565px) {
            width: 300px;
        }

        @media only screen and (max-width: 500px) {
            width: 185px;
        }

        @media only screen and (max-width: 400px) {
            width: 140px;
        }
    }

    &-info {
        &-description-distance {
            display: flex;
            font-size: 13px;
            opacity: 0.8;
            margin-top: 5px;
        }

        &-container {
            height: 100%;
            width: 100%;
            margin-left: 10px;
        }
    }
}

.no-location {
    display: flex;
    align-items: center;
    text-align: center;
}
